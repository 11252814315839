body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-rotate{
  transition: transform .7s ease-in-out;
}
.hover-rotate:hover {
  transform: rotate(180deg);
}

.hide-underline {
  text-decoration: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

.nav-container {
  width: 95%;
  margin: auto;
}

/** TEMPORARY DUMMY ITEM STYLES **/
body.battlescribe {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  border-width: 0px;
  
  font-family: sans-serif;
  font-size: 12px;
  color: #444444;
  text-align: left;
}

div.battlescribe h1,
div.battlescribe h2,
div.battlescribe h3,
div.battlescribe h4 {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe h1 {
  margin: 8px 0px 0px 0px;
  
  font-size: 16px;
}

div.battlescribe h2 {
  font-size: 15px;
}

div.battlescribe h3 {
  font-size: 14px;
}

div.battlescribe h4 {
  font-size: 13px;
}

div.battlescribe div.summary {
  margin: 16px 0px 0px 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe ul {
  margin: 0px 0px 0px 16px;
  padding: 0px;
  border-width: 0px;
  
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
}

div.battlescribe li {
  margin: 8px 0px 0px 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe li.force {
  margin: 24px 0px 0px 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe li.category {
  margin: 16px 0px 0px 0px;
  padding: 0px;
  border-width: 0px;
}

div.battlescribe li.rootselection {
  margin: 16px 0px 0px 0px;
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #BBBBBB;
  
  page-break-inside: avoid;
}

div.battlescribe p {
  margin: 4px 0px 0px 16px;
  padding: 0px;
  border-width: 0px;
  
  font-size: 12px;
}

div.battlescribe table {
  margin: 8px 0px 0px 16px;
  padding: 0px;
  border-collapse: collapse;
  
  font-size: 12px;
  color: #444444;
  
  page-break-inside: avoid;
}

div.battlescribe tr {
  border-width: 1px;
  border-style: solid;
  border-color: #BBBBBB;
}

div.battlescribe th {
  padding: 4px;
  margin: 0px;
  border-width: 0px;
  
  font-weight: bold;
  text-align: left;
}

div.battlescribe td {
  padding: 4px;
  margin: 0px;
  border-width: 0px;
  
  text-align: left;
}

div.battlescribe td.profile-name {
  font-weight: bold;
}

div.battlescribe td.statistic-name {
  font-weight: bold;
}

div.battlescribe table.statistics tr.subtotal {
  font-weight: bold;
}

div.battlescribe table.statistics tr.total {
  font-size: 13px;
  font-weight: bold;
}

div.battlescribe table.statistics th {
  border-width: 1px;
  border-style: solid;
  border-color: #BBBBBB;
  
  font-size: 13px;
  text-align: right;
}

div.battlescribe table.statistics th.center {
  text-align: center;
}

div.battlescribe table.statistics td {
  border-width: 1px;
  border-style: solid;
  border-color: #BBBBBB;
  
  text-align: right;
}

div.battlescribe span.bold {
  font-weight: bold;
}

div.battlescribe span.italic {
  font-style: italic;
}

div.battlescribe span.caps {
  font-variant: small-caps;
}